<template>
  <v-container
    fluid
  >
    <div
      v-if="showTooltip"
      ref="svgTooltip"
      class="d-flex justify-center align-center pa-2"
      :style="`position: fixed; top: ${tooltipY}px; left: ${tooltipX}px;
            min-width: 100px; min-height: 50px; background: white; border-radius: 15px;
            opacity: .9; z-index: 5;
          `"
    >
      <span>
        {{ tooltipText }}
      </span>
    </div>
    <v-row>
      <v-col
        class="pa-0"
        cols="12"
      >
        <v-container
          fluid
          class="mb-0 py-0 px-6"
        >
          <v-card
            style="width: 100%; height: 100%;"
          >
            <ewc-toolbar
              :pending-requests-count="pendingRequestsCount"
            >
              <template v-slot:siteSelection>
                <v-col
                  cols="4"
                  md="3"
                >
                  <v-select
                    id="ewcSiteSelectionDropdown"
                    v-model="selectedSite"
                    :items="sites"
                    label="Select Site"
                    item-text="siteName"
                    :loading="loadingSites"
                    return-object
                    class="mt-7"
                    outlined
                    dense
                    flat
                    @change=" (selection) => {
                      showAlert = false
                      store.set('app/disableEwc', false)
                      grids = selection.siteGrids
                      if(grids.length === 1){
                        selectedFloor = grids[0]
                        onSelectFloor(selectedFloor)
                      } else {
                        selectedFloor = ''
                        if(grids.length === 0){
                          showAlert = true
                          alertType = 'info'
                          alertMessage = aletMessageSiteNotSetUp
                          store.set('app/disableEwc', true)
                        }
                      }
                    }
                    "
                  />
                </v-col>
                <v-col
                  cols="4"
                  md="3"
                >
                  <v-select
                    id="ewcFloorplanSelectionDropdown"
                    v-model="selectedFloor"
                    :disabled="loadingFloorplan || !selectedSite"
                    :loading="loadingFloorplan"
                    :items="grids"
                    label="Select Floorplan"
                    item-text="name"
                    return-object
                    class="mt-7"
                    outlined
                    dense
                    flat
                    @change=" (selection) => {
                      onSelectFloor(selection)
                    }"
                  />
                </v-col>
              </template>
              <template v-slot:pageSpecificIcons>
                <v-btn
                  v-show="superAdmin && gridView"
                  color="green"
                  class="font-weight-bold white--text"
                  :text="!showVerified"
                  :disabled="!selectedFloor"
                  @click="() => {
                    if (showVerified){
                      localBus.$emit('show-audit-verified', [])

                    } else {
                      localBus.$emit('show-audit-verified', auditVerifiedRacks)

                    }
                    showVerified = !showVerified
                  }
                  "
                >
                  Show Verified
                </v-btn>
                <v-btn
                  v-if="gridView"
                  color="blue"
                  class="font-weight-bold white--text mx-1"
                  :text="!showGridLines"
                  :disabled="!selectedFloor"
                  @click="() => {
                    if (showGridLines){
                      localBus.$emit('hide-grid-lines')

                    } else {
                      localBus.$emit('show-grid-lines')

                    }
                    showGridLines = !showGridLines
                  }
                  "
                >
                  Grid Lines
                </v-btn>
                <v-btn
                  color="primary"
                  class="font-weight-bold"
                  :text="!gridView"
                  :disabled="!selectedFloor"
                  @click="
                    switchFromTableToGridSelect()
                  "
                >
                  Grid View
                </v-btn>
                <v-btn
                  color="primary"
                  class="font-weight-bold mr-2"
                  :text="gridView"
                  :disabled="!selectedFloor"
                  @click="
                    gridView = false
                    updateSvg = -1
                    switchFromGridToTableSelect()
                  "
                >
                  List View
                </v-btn>
              </template>
            </ewc-toolbar>
          </v-card>
        </v-container>
      </v-col>
      <v-col
        v-if="showAlert"
        class="px-6 pb-0"
        cols="12"
      >
        <v-alert
          :type="alertType"
          class="mb-0"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="selectedFloor">
      <v-col
        v-show="gridView"
        cols="12"
        lg="8"
        style="position: relative;"
      >
        <div
          style="height: 800px; width: 100%;"
        >
          <grid-wrapper
            :redraw-grid="gridView.toString()"
            :floorplan="floorplan"
            :loading-floorplan="loadingFloorplan"
            :local-bus="localBus"
          />
        </div>
      </v-col>
      <v-col
        v-show="selectedFloor && gridView"
        :class="`${$vuetify.breakpoint.lgAndUp ? 'pl-0 py-6' : 'pl-6 py-0'} pr-6`"
        cols="12"
        lg="4"
      >
        <v-row>
          <v-col
            class="pa-0"
            cols="12"
          >
            <div :style="`height: ${infoCardHeight}; width: 100%;`">
              <ewc-information-card
                :update-svg="updateSvg"
                :gridless-item="gridlessItem"
                :local-bus="localBus"
              />
            </div>
          </v-col>
          <v-col
            v-show="selectedEnclosure"
            class="pa-0"
            cols="12"
          >
            <div :style="`height: ${enclosureCardHeight}; width: 100%;`">
              <ewc-enclosure-card
                :update-svg="updateSvg"
                :local-bus="localBus"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="selectedGridViewEquipment.length > 0 && gridView"
        cols="12"
        class="px-6 py-0"
      >
        <ewc-single-selected-info

          :key="selectedGridViewEquipment.equipmentEwcId"
          :selected-equipment-data="selectedGridViewEquipment"
        />
      </v-col>
      <v-col
        v-show="!gridView"
        cols="12"
        class="py-o"
      >
        <div
          style="height: 800px; width: 100%;"
        >
          <v-container
            fluid
            style="width: 100%; height: 100%"
          >
            <v-card
              style="width: 100%; height: 100%"
              class="pa-2"
            >
              <v-data-table
                ref="equipmentDataTable"
                :headers="headers"
                single-select
                item-key="id"
                :loading="loadingFloorplan"
                loading-text="Retrieving Equipment"
                :items="equipmentDataTableArray"
                :fixed-header="true"
                calculate-widths
                :options.sync="pagination"
                height="700"
                :value="selectedRow"
                @pagination="event => {
                  renderedEquipmentDataTableLength = event.itemsLength
                }"
                @click:row="
                  (item, row) => {
                    row.select(true)
                    selectedTableItem = item
                  }
                "
              >
                <template
                  v-for="header in headers.filter((header) =>
                    header.hasOwnProperty('formatter')
                  )"
                  v-slot:[`item.${header.value}`]="{ value }"
                >
                  {{ header.formatter(value) }}
                </template>
                <template v-slot:[`body.prepend`]>
                  <tr>
                    <td>
                      <v-text-field
                        v-model="tableFieldLabel"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                        label="filter..."
                      />
                    </td>
                    <td>
                      <v-select
                        v-model="tableFieldStatus"
                        :items="['Arrived', 'Deployed', 'Decommissioned', 'Disposed']"
                        class="pt-4"
                        outlined
                        dense
                      >
                        <v-btn
                          v-if="tableFieldStatus"
                          slot="prepend-inner"
                          x-small
                          style="margin-top: 3px;"
                          text
                          @click="tableFieldStatus = ''"
                        >
                          <v-icon size="small">
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-select>
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldSerial"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldAssetTag"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldModel"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldRackLocation"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldUnitLocation"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldBay"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldNotes"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldAssetId"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldProjectNumber"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldProjectName"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldDepartment"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldAcquisitionDate"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldDecommissionDate"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldDisposalDate"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                    <td>
                      <v-text-field
                        v-model="tableFieldDescription"
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                      />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-container>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showDownloadDialog"
      max-width="400"
    >
      <v-card class="py-4 px-6">
        <v-card-title class="text-h5 pl-0">
          Download CSV
        </v-card-title>
        <v-select
          v-model="selectedSiteCsv"
          :items="sitesCsv"
          label="Select Site"
          item-text="siteName"
          :loading="loadingSites"
          return-object
          class="mt-5"
          outlined
          dense
          flat
          @change=" (selection) => {
            showAlertCsv = false
            store.set('app/disableEwc', false)
            if(selection === 'All Sites') return
            grids = selection.siteGrids
            if(grids.length === 1){
              selectedFloorCsv = grids[0]
            } else {
              selectedFloorCsv = ''
              if(grids.length === 0){
                showAlertCsv = true
                alertType = 'info'
                alertMessage = aletMessageSiteNotSetUp
                store.set('app/disableEwc', true)
              }
            }
          }
          "
        />
        <transition-group>
          <v-select
            v-if="selectedSiteCsv && selectedSiteCsv !== 'All Sites' && grids.length > 0"
            key="0"
            v-model="selectedFloorCsv"
            :items="gridsCsv"
            label="Select Floorplan"
            item-text="name"
            return-object
            class="mt-2"
            outlined
            dense
            flat
          />
          <v-checkbox
            v-if="(selectedSite === selectedSiteCsv && selectedFloor === selectedFloorCsv) && renderedEquipmentDataTableLength && renderedEquipmentDataTableLength !== equipmentDataTableArray.length"
            key="1"
            v-model="csvCheckbox"
            style="margin-top: -3px;"
            label="Download filtered list of equipment"
          />
        </transition-group>
        <transition-group v-if="selectedSiteCsv === 'All Sites' || (selectedSiteCsv && selectedFloorCsv)">
          <div
            key="0"
            class="d-flex"
          >
            <v-text-field
              v-model="filename"
              label="Filename"
            />
            <span class="pt-8 ml-1">.csv</span>
          </div>
          <v-card-actions
            key="1"
            class="d-flex justify-center"
          >
            <v-btn
              color="green darken-1"
              text
              :loading="downloadingCsv"
              :disabled="selectedSiteCsv !== 'All Sites' && !selectedFloorCsv"
              @click="initiateCsvDownload"
            >
              Save
            </v-btn>
            <v-btn
              color="grey darken-1"
              text
              @click="showDownloadDialog = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </transition-group>
      </v-card>
      <v-alert
        v-if="showAlertCsv"
        :type="alertType"
        class="mb-0 mt-1"
      >
        {{ alertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showEditWarningDialog"
      max-width="400"
    >
      <v-card class="py-4 pl-6">
        <v-card-title class="text-h5 pl-0">
          Warning:
        </v-card-title>
        <div
          class="d-flex pr-6"
        >
          This equipment is currently being edited by another user. Please complete the Pending Edit request before making further changes to the equipment.
        </div>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="green darken-1"
            text
            @click="showEditWarningDialog = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      fixed
      transition="slide-y-reverse-transition"
    >
      <template v-slot:activator>
        <v-btn
          color="blue darken-2"
          dark
          fab
        >
          <v-icon v-if="fab">
            {{ mdiChevronDown }}
          </v-icon>
          <v-icon v-else>
            {{ mdiChevronUp }}
          </v-icon>
        </v-btn>
      </template>
      <v-tooltip
        left
        :value="tooltips"
        :disabled="false"
        transition="fade-transition"
      >
        <template v-slot:activator="{on}">
          <v-btn
            color="blue-grey darken-1"
            fab
            :disabled="!selectedItemForEdit"
            :loading="checkingPendingEdits"
            @click="navigateToEditEquipment"
          >
            <v-icon color="white">
              {{ mdiPencilOutline }}
            </v-icon>
          </v-btn>
          <div
            style="width: 0px;"
            v-on="on"
          />
        </template>
        <span class="pb-10 mb-2">{{ editButtonLabel }}</span>
      </v-tooltip>
      <v-tooltip
        left
        :value="tooltips"
        :disabled="false"
        transition="fade-transition"
      >
        <template v-slot:activator="{on}">
          <v-btn
            color="cyan"
            fab
            @click="
              selectedSiteCsv = selectedSite
              grids = selectedSite.siteGrids
              selectedFloorCsv = selectedFloor
              showAlertCsv = false
              showDownloadDialog = true
            "
          >
            <v-icon color="white">
              {{ mdiDownload }}
            </v-icon>
          </v-btn>
          <div
            style="width: 0px;"
            v-on="on"
          />
        </template>
        <span> Download as CSV </span>
      </v-tooltip>
      <v-tooltip
        v-if="superAdmin"
        left
        :value="tooltips"
        :disabled="false"
        transition="fade-transition"
      >
        <template v-slot:activator="{on}">
          <v-btn
            color="deep-purple"
            fab
            :loading="insertingAuditEquipment"
            :disabled="!selectedItemForEdit"
            @click="insertAuditPotentialRemoved"
          >
            <v-icon color="white">
              {{ mdiClipboardPlusOutline }}
            </v-icon>
          </v-btn>
          <div
            style="width: 0px;"
            v-on="on"
          />
        </template>
        <span> Add to audit missing </span>
      </v-tooltip>
    </v-speed-dial>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import ewcApi from '@/api/ewc.js'
  import common from '@/api/common.js'
  import ewcHolder from '@/util/ewc-holder'
  import equipmentApi from '@/api/equipment'
  import { convertEwcEquipmentToCSV, convertEwcEquipmentToCSVAllSites, convertEwcEquipmentToCSVAllFloorplans } from '@/util/converters'
  import messageHolder from '@/util/message-holder'
  import moment from 'moment'
  import store from '@/store'
  import { mdiPencilOutline, mdiDownload, mdiChevronDown, mdiChevronUp, mdiClipboardPlusOutline } from '@mdi/js'

  export default {
    name: 'EWC',
    data: () => ({
      localBus: new Vue(),
      sites: [],
      grids: [],
      selectedSite: '',
      selectedFloor: '',
      loadingSites: false,

      floorplan: {},
      loadingFloorplan: false,

      showAlert: false,
      alertType: 'error',
      alertMessage: '',
      alertMessageDataCenters: 'There was an error loading Data Centers. Please try again later.',
      alertMessageFloorplan: 'There was an error loading Floorplan. Please try again later.',
      alertMessageEquipmentTable: 'There was an error loading Equipment Table. Please try again later.',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please, try again later.',
      alertMessageNoFloorplans: 'Your Sites are currently not set up with Floorplans for use with EWC. Please contact us if you would like more information about this feature.',
      aletMessageSiteNotSetUp: 'This site is currently not set up with Floorplans for use with EWC. Please contact us if you are interested in adding this site to EWC.',
      alertMessageDuplicate: 'The equipment you are attempting to add is already placed in the audit potentially removed table',

      showSnackBar: false,
      snackBarMessage: '',

      selectedFloorItem: null,
      selectedRackItem: null,
      selectedEnclosure: null,
      selectedEnclosureItem: null,

      gridView: true,

      selectedTableItem: null,
      tableFieldLabel: '',
      tableFieldStatus: '',
      tableFieldSerial: '',
      tableFieldAssetTag: '',
      tableFieldModel: '',
      tableFieldRackLocation: '',
      tableFieldUnitLocation: '',
      tableFieldBay: '',
      tableFieldNotes: '',
      tableFieldAssetId: '',
      tableFieldProjectNumber: '',
      tableFieldProjectName: '',
      tableFieldDepartment: '',
      tableFieldAcquisitionDate: '',
      tableFieldDecommissionDate: '',
      tableFieldDisposalDate: '',
      tableFieldDescription: '',
      selectedRow: [],
      renderedEquipmentDataTableLength: 0,

      pagination: {
        itemsPerPage: 10,
      },

      filename: '',
      showDownloadDialog: false,
      selectedFloorCsv: '',
      selectedSiteCsv: '',
      csvCheckbox: false,
      showAlertCsv: false,
      downloadingCsv: false,

      gridlessItem: null,
      updateSvg: -1,

      showTooltip: false,
      tooltipY: 0,
      tooltipX: 0,
      tooltipText: '',

      ewcHolder: ewcHolder,

      checkingPendingEdits: false,
      showEditWarningDialog: false,

      fab: false,
      tooltips: false,

      store: store,

      mdiPencilOutline: mdiPencilOutline,
      mdiDownload: mdiDownload,
      mdiChevronDown: mdiChevronDown,
      mdiChevronUp: mdiChevronUp,
      mdiClipboardPlusOutline: mdiClipboardPlusOutline,

      insertingAuditEquipment: false,
      auditVerifiedRacks: [],
      showVerified: false,

      showGridLines: true,

    }),

    computed: {
      infoCardHeight () {
        if (!this.selectedEnclosure || Object.keys(this.selectedEnclosure).length === 0) {
          return '800px'
        } else {
          if (this.selectedEnclosure.equipmentModel?.modelRows < 4) {
            return `${800 - this.selectedEnclosure.equipmentModel?.modelRows * 120}px`
          } else {
            return '400px'
          }
        }
      },
      enclosureCardHeight () {
        if (!this.selectedEnclosure || Object.keys(this.selectedEnclosure).length === 0) return '0px'
        if (this.selectedEnclosure.equipmentModel?.modelRows < 4) {
          return `${this.selectedEnclosure.equipmentModel?.modelRows * 120}px`
        } else {
          return '400px'
        }
      },

      headers () {
        return [
          {
            text: 'Label',
            value: 'label',
            width: '150',
            filter: value => {
              if (!this.tableFieldLabel) return true
              return (value)?.toLowerCase().includes(this.tableFieldLabel.toLowerCase())
            },
          },
          {
            text: 'Status',
            value: 'status',
            width: '200',
            filter: value => {
              if (!this.tableFieldStatus) return true
              return (value)?.toLowerCase().includes(this.tableFieldStatus.toLowerCase())
            },
          },
          {
            text: 'Serial',
            value: 'serial',
            width: '150',
            filter: value => {
              if (!this.tableFieldSerial) return true
              return (value)?.toLowerCase().includes(this.tableFieldSerial.toLowerCase())
            },
          },
          {
            text: 'Asset Tag',
            value: 'assetTag',
            width: '150',
            filter: value => {
              if (!this.tableFieldAssetTag) return true
              return (value)?.toLowerCase().includes(this.tableFieldAssetTag.toLowerCase())
            },
          },
          {
            text: 'Model',
            value: 'model',
            width: '150',
            filter: value => {
              if (!this.tableFieldModel) return true
              return (value)?.toLowerCase().includes(this.tableFieldModel.toLowerCase())
            },
          },
          {
            text: 'Rack Location',
            value: 'rackLocation',
            width: '200',
            filter: value => {
              if (!this.tableFieldRackLocation) return true
              return (value)?.toLowerCase().includes(this.tableFieldRackLocation.toLowerCase())
            },
          },
          {
            text: 'Unit Location',
            value: 'unitLocation',
            width: '200',
            filter: value => {
              if (!this.tableFieldUnitLocation) return true
              return (value)?.toLowerCase().includes(this.tableFieldUnitLocation.toLowerCase())
            },

          },
          {
            text: 'Bay',
            value: 'bay',
            width: '100',
            filter: value => {
              if (!this.tableFieldBay) return true
              return (value)?.toLowerCase().includes(this.tableFieldBay.toLowerCase())
            },
          },
          {
            text: 'Notes',
            value: 'notes',
            width: '300',
            filter: value => {
              if (!this.tableFieldNotes) return true
              return (value)?.toLowerCase().includes(this.tableFieldNotes.toLowerCase())
            },
          },
          {
            text: 'Asset Id',
            value: 'assetId',
            width: '150',
            filter: value => {
              if (!this.tableFieldAssetId) return true
              return (value)?.toLowerCase().includes(this.tableFieldAssetId.toLowerCase())
            },
          },
          {
            text: 'Project Number',
            value: 'projectNumber',
            width: '200',
            filter: value => {
              if (!this.tableFieldProjectNumber) return true
              return (value)?.toLowerCase().includes(this.tableFieldProjectNumber.toLowerCase())
            },
          },
          {
            text: 'Project Name',
            value: 'projectName',
            width: '200',
            filter: value => {
              if (!this.tableFieldProjectName) return true
              return (value)?.toLowerCase().includes(this.tableFieldProjectName.toLowerCase())
            },
          },
          {
            text: 'Department',
            value: 'departmentNumber',
            width: '150',
            filter: value => {
              if (!this.tableFieldDepartment) return true
              return (value)?.toLowerCase().includes(this.tableFieldDepartment.toLowerCase())
            },
          },
          {
            text: 'Acquisition Date',
            value: 'acquisitionDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldAcquisitionDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldAcquisitionDate.toLowerCase())
            },
          },
          {
            text: 'Decommission Date',
            value: 'decommissionDate',
            width: '220',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldDecommissionDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldDecommissionDate.toLowerCase())
            },
          },
          {
            text: 'Disposal Date',
            value: 'disposalDate',
            width: '200',
            formatter: (x) => x ? moment(x).format('YYYY/MM/DD') : '',
            filter: value => {
              if (!this.tableFieldDisposalDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldDisposalDate.toLowerCase())
            },
          },
          {
            text: 'Description',
            value: 'description',
            width: '150',
            filter: value => {
              if (!this.tableFieldDescription) return true
              return (value)?.toLowerCase().includes(this.tableFieldDescription.toLowerCase())
            },
          },
        ]
      },

      equipmentDataTableArray () {
        if (Object.keys(this.floorplan).length && !this.loadingFloorplan) {
          const active = this.floorplan.activeEquipmentEwc.map(equipment => this.parseEquipment(equipment)).flat()
          const disposed = this.floorplan.disposedEquipmentEwc.map(equipment => this.parseEquipment(equipment)).flat()
          return active.concat(disposed).sort((a, b) => a.equipmentEwcId - b.equipmentEwcId)
        } else return []
      },

      pendingRequestsCount () {
        if (Object.keys(this.floorplan).length && !this.loadingFloorplan) {
          return this.floorplan.equipmentRequests.length
        } else return 0
      },

      selectedItemForEdit () {
        if (!this.gridView && this.selectedTableItem) {
          return this.selectedTableItem
        } else if (this.selectedEnclosureItem) {
          return this.selectedEnclosureItem
        } else if (this.selectedRackItem || this.selectedEnclosure) {
          return this.selectedRackItem || this.selectedEnclosure
        } else if (this.selectedFloorItem) {
          return this.selectedFloorItem
        } else {
          return null
        }
      },

      editButtonLabel () {
        if (!this.selectedItemForEdit) {
          return 'Edit selected equipment'
        } else if (this.gridView) {
          return `Edit ${this.selectedItemForEdit.equipmentData.label ? this.selectedItemForEdit.equipmentData.label : this.selectedItemForEdit.equipmentModel.name}`
        } else {
          return `Edit ${this.selectedItemForEdit.label ? this.selectedItemForEdit.label : this.selectedItemForEdit.model}`
        }
      },

      sitesCsv () {
        if (this.sites.length === 1) return this.sites
        return [...this.sites, 'All Sites']
      },
      gridsCsv () {
        if (this.grids.length === 1) return this.grids
        return [...this.grids, 'All Floorplans']
      },

      superAdmin: function () {
        return store.get('user/role') === 3
      },
      selectedGridViewEquipment () {
        if (!this.gridView || !this.selectedFloorItem) {
          return []
        } else if (this.selectedEnclosureItem) {
          return [{ ...this.selectedEnclosureItem.equipmentData, model: this.selectedEnclosureItem.equipmentModel.name, ...this.selectedEnclosureItem.location }]
        } else if (this.selectedRackItem) {
          return [{ ...this.selectedRackItem.equipmentData, model: this.selectedRackItem.equipmentModel.name, ...this.selectedRackItem.location }]
        } else if (this.selectedEnclosure) {
          return [{ ...this.selectedEnclosure.equipmentData, model: this.selectedEnclosure.equipmentModel.name, ...this.selectedEnclosure.location }]
        } else {
          return [{ ...this.selectedFloorItem.equipmentData, model: this.selectedFloorItem.equipmentModel.name, ...this.selectedFloorItem.location }]
        }
      },

    },

    watch: {
      fab (val) {
        this.tooltips = false
        val && setTimeout(() => {
          this.tooltips = true
        }, 250)
      },

      selectedSiteCsv (val) {
        if (val === 'All Sites') {
          this.filename = 'all-equipment'
        }
      },
      selectedFloorCsv (val) {
        if (val === 'All Floorplans') {
          this.filename = this.selectedSiteCsv.siteName
        } else {
          this.filename = `${this.selectedSiteCsv.siteName}-${val.name}`
        }
      },
    },

    created () {
      ewcHolder.getDataCenters() ? this.sites = ewcHolder.getDataCenters() : this.getDataCentersForUser()
      if (ewcHolder.getLatestSiteAndFloorSelection()) {
        this.selectedSite = ewcHolder.getLatestSiteAndFloorSelection().site
        this.grids = this.selectedSite.siteGrids
        this.selectedFloor = ewcHolder.getLatestSiteAndFloorSelection().floor
      }

      if (this.selectedFloor) {
        this.getEquipmentEwcForSite(this.selectedFloor.id)
        const latestSelection = ewcHolder.getLatestMainEwcPageSelection()
        if (latestSelection && this.equipmentDataTableArray.length > 0) {
          this.equipmentDataTableArray.forEach(tableItem => {
            if (tableItem.equipmentEwcId === latestSelection.equipmentData.id) {
              this.selectedTableItem = tableItem
              setTimeout(() => {
                this.switchFromTableToGridSelect()
              }, [0])
            }
          })
        }
        if (this.superAdmin) {
          this.getAllAuditVerified(this.selectedFloor.id)
        }
      }

      this.localBus.$on('onSelectFloorItem', floorItem => {
        this.selectedFloorItem = floorItem
        this.selectedEnclosure = null
        this.selectedRackItem = null
        this.selectedEnclosureItem = null
        this.showVerified = false
        ewcHolder.setLatestMainEwcPageSelection(floorItem)
      })
      this.localBus.$on('clearSelectedFloorItem', () => {
        this.selectedFloorItem = null
        this.selectedEnclosure = null
        this.selectedRackItem = null
        this.selectedEnclosureItem = null
        ewcHolder.setLatestMainEwcPageSelection(null)
      })
      this.localBus.$on('onSelectRackItem', rackItem => {
        this.selectedEnclosure = null
        this.selectedEnclosureItem = null
        this.selectedRackItem = rackItem
        ewcHolder.setLatestMainEwcPageSelection(rackItem)
      })
      this.localBus.$on('clearSelectedRackItem', () => {
        this.selectedEnclosure = null
        this.selectedEnclosureItem = null
        this.selectedRackItem = null
        ewcHolder.setLatestMainEwcPageSelection(this.selectedRackItem)
      })
      this.localBus.$on('onSelectEnclosure', enclosure => {
        this.selectedEnclosure = enclosure
        this.selectedEnclosureItem = null
        this.selectedRackItem = null
        ewcHolder.setLatestMainEwcPageSelection(enclosure)
      })
      this.localBus.$on('onSelectEnclosureItem', enclosureItem => {
        this.selectedEnclosureItem = enclosureItem
        ewcHolder.setLatestMainEwcPageSelection(enclosureItem)
      })
      this.localBus.$on('clearSelectedEnclosureItem', () => {
        this.selectedEnclosureItem = null
        ewcHolder.setLatestMainEwcPageSelection(this.selectedEnclosure)
      })

      this.localBus.$on('display-tooltip', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = label
      })
      this.localBus.$on('hide-tooltip', () => {
        this.showTooltip = false
      })

      this.localBus.$on('change-verified-success', () => {
        this.getAllAuditVerified(this.selectedFloor.id)
      })
      this.localBus.$on('audit-verification-error', errorMessage => {
        this.showAlert = true
        this.alertMessage = errorMessage
      })

      if (messageHolder.getMessage()) {
        this.showSnackBar = true
        this.snackBarMessage = messageHolder.getMessage()
        messageHolder.setMessage('')
      }
    },

    methods: {
      getDataCentersForUser () {
        if (ewcHolder.getDataCenters()) {
          this.sites = ewcHolder.getDataCenters()
          if (this.sites.length === 1) {
            this.selectedSite = this.sites[0]
            this.grids = this.selectedSite.siteGrids
          }
          return
        }
        this.loadingSites = true
        ewcApi.getDataCentersForUser()
          .then(response => {
            console.log(response)
            this.sites = response.data
            if (this.checkForFloorPlans(this.sites)) {
              if (this.sites.length === 1) {
                this.selectedSite = this.sites[0]
                this.grids = this.selectedSite.siteGrids
              }
              store.set('app/disableEwc', false)
            } else {
              this.showAlert = true
              this.alertType = 'info'
              this.alertMessage = this.alertMessageNoFloorplans
              store.set('app/disableEwc', true)
            }
            this.loadingSites = false
            ewcHolder.setDataCenters(response.data)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getDataCentersForUser()
            } else {
              this.loadingSites = false
              this.showAlert = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageDataCenters
            }
          })
      },
      checkForFloorPlans (sites) {
        let floorplansPresent = false
        sites.forEach(site => {
          if (site.siteGrids.length > 0) {
            floorplansPresent = true
          }
        })
        return floorplansPresent
      },

      getEquipmentEwcForSite (gridId) {
        this.loadingFloorplan = true
        const floorplan = ewcHolder.getFloorplan()
        if (floorplan?.dataCenterGrid.id === parseInt(gridId)) {
          if (floorplan.activeEquipmentEwc.length > 100) {
            setTimeout(() => {
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = floorplan })
            }, [0])
          } else {
            this.loadingFloorplan = false
            this.floorplan = floorplan
          }
        } else {
          equipmentApi.getEquipmentEwcForSite(gridId)
            .then((response) => {
              console.log(response)
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = response.data })
              ewcHolder.setFloorplan(response.data)
            })
            .catch(async (error) => {
              console.log(error)
              if (await common.handleBadCall(error, this.$router) === true) {
                this.getEquipmentEwcForSite(gridId)
              } else {
                this.loadingFloorplan = false
                this.showAlert = true
                this.alertType = 'error'
                this.alertMessage = this.alertMessageFloorplan
                this.selectedFloor = ''
              }
            })
        }
      },
      // note equipmentData, equipmentModel, and location all have an id field that conflict when using array.flat() so the best field to use for equipment id is equipmentEwcId
      parseEquipment (equipment) {
        const array = [{ ...equipment.equipmentData, model: equipment.equipmentModel.name, ...equipment.location }]
        equipment.children.forEach(c => array.push(this.parseEquipment(c)))
        return array.flat()
      },

      initiateCsvDownload () {
        this.downloadingCsv = true
        this.showAlertCsv = false
        if (this.selectedSite === this.selectedSiteCsv && this.selectedFloor === this.selectedFloorCsv) {
          this.csvCheckbox ? this.downloadCSVFromTable(this.$refs.equipmentDataTable.selectableItems) : this.downloadCSVFromTable(this.equipmentDataTableArray)
        } else if (this.selectedSiteCsv === 'All Sites') {
          this.downloadAllSitesCSVFromServer()
        } else if (this.selectedFloorCsv === 'All Floorplans') {
          this.downloadAllFloorplansCSVFromServer()
        } else {
          this.downloadSingleFloorplanCSVFromServer()
        }
      },
      downloadCSVFromTable: function (items) {
        const currentPagination = { ...this.pagination }
        this.pagination = { itemsPerPage: -1 }
        this.$nextTick(() => {
          const csv = convertEwcEquipmentToCSV(items)
          const encodedData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
          const link = document.createElement('a')

          link.setAttribute('href', encodedData)
          link.setAttribute('download', `${this.filename}.csv`)
          link.click()
          this.showDownloadDialog = false
          this.downloadingCsv = false
          this.pagination = currentPagination
        })
      },
      downloadCSVFromServer (items, allSites, allFloorplans) {
        const csv = allSites ? convertEwcEquipmentToCSVAllSites(items) : allFloorplans ? convertEwcEquipmentToCSVAllFloorplans(items) : convertEwcEquipmentToCSV(items)
        const encodedData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        const link = document.createElement('a')

        link.setAttribute('href', encodedData)
        link.setAttribute('download', `${this.filename}.csv`)
        link.click()
        this.showDownloadDialog = false
      },
      downloadAllSitesCSVFromServer () {
        let skipFinallyBlock = false
        ewcApi.getAllEquipmentEwcForTenant()
          .then(response => {
            console.log(response)

            this.downloadCSVFromServer(response.data.equipmentEwc.map(equipment => {
              return {
                ...equipment.equipmentEwc,
                gridName: equipment.gridName,
                modelName: equipment.modelName,
                siteName: equipment.siteName,
              }
            },
            ), true, false,
            )
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.downloadAllSitesCSVFromServer()
            } else {
              this.showAlertCsv = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.downloadingCsv = false
            }
          })
      },
      downloadAllFloorplansCSVFromServer () {
        let skipFinallyBlock = false
        ewcApi.getAllEquipmentEwcForSite(this.selectedSiteCsv.siteId)
          .then(response => {
            console.log(response)

            this.downloadCSVFromServer(response.data.equipmentEwc.map(equipment => {
              return {
                ...equipment.equipmentEwc,
                gridName: equipment.gridName,
                modelName: equipment.modelName,
              }
            },
            ), false, true,
            )
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.downloadAllFloorplansCSVFromServer()
            } else {
              this.showAlertCsv = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.downloadingCsv = false
            }
          })
      },
      downloadSingleFloorplanCSVFromServer () {
        let skipFinallyBlock = false
        ewcApi.getAllEquipmentEwcForFloorplan(this.selectedFloorCsv.id)
          .then(response => {
            console.log(response)

            this.downloadCSVFromServer(response.data.equipmentEwc.map(equipment => {
              return {
                ...equipment.equipmentEwc,
                model: equipment.modelName,
              }
            },
            ), false, false,
            )
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.downloadSingleFloorplanCSVFromServer()
            } else {
              this.showAlertCsv = true
              this.alertType = 'error'
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.downloadingCsv = false
            }
          })
      },

      onSelectFloor (selection) {
        this.showAlert = false
        this.selectedTableItem = null
        this.selectedEnclosure = null
        this.selectedEnclosureItem = null
        this.selectedFloorItem = null
        this.selectedRackItem = null
        this.localBus.$emit('clearSelectedItems')

        ewcHolder.setLatestSiteAndFloorSelection({ site: this.selectedSite, floor: this.selectedFloor })
        this.getEquipmentEwcForSite(selection.id)
        if (this.superAdmin) {
          this.getAllAuditVerified(selection.id)
        }
      },

      switchFromGridToTableSelect () {
        if (this.selectedEnclosureItem) {
          this.assignTableField(this.selectedEnclosureItem)
        } else if (this.selectedEnclosure) {
          this.assignTableField(this.selectedEnclosure)
        } else if (this.selectedRackItem) {
          this.assignTableField(this.selectedRackItem)
        } else if (this.selectedFloorItem) {
          this.assignTableField(this.selectedFloorItem)
        }
      },
      assignTableField (selectedItem) {
        this.tableFieldRackLocation = selectedItem.equipmentData.rackLocation
        const index = this.binarySearch(this.equipmentDataTableArray, 0, this.equipmentDataTableArray.length - 1, selectedItem.location.equipmentEwcId)

        if (index !== -1) this.equipmentDataTableArray.unshift(this.equipmentDataTableArray.splice(index, 1)[0])
        if (this.equipmentDataTableArray[0].equipmentEwcId === selectedItem.location.equipmentEwcId) {
          this.selectedRow = [this.equipmentDataTableArray[0]]
          this.selectedTableItem = this.equipmentDataTableArray[0]
        } else {
          this.selectedRow = [this.equipmentDataTableArray[index]]
          this.selectedTableItem = this.equipmentDataTableArray[index]
        }

        this.tableFieldLabel = ''
        this.tableFieldModel = ''
        this.tableFieldUnitLocation = ''
        this.tableFieldBay = ''
        this.tableFieldNotes = ''
        this.tableFieldAssetId = ''
        this.tableFieldProjectNumber = ''
        this.tableFieldProjectName = ''
        this.tableFieldDepartment = ''
        this.tableFieldAcquisitionDate = ''
        this.tableFieldDecommissionDate = ''
        this.tableFieldDisposalDate = ''
        this.tableFieldDescription = ''
      },
      binarySearch (array, left, right, value) {
        if (right >= left) {
          const mid = left + Math.floor((right - left) / 2)
          if (array[mid].equipmentEwcId === value) return mid
          if (array[mid].equipmentEwcId > value) return this.binarySearch(array, left, mid - 1, value)
          return this.binarySearch(array, mid + 1, right, value)
        }
        return -1
      },
      switchFromTableToGridSelect () {
        this.localBus.$emit('clearSelectedItems')
        this.selectedEnclosure = null
        this.selectedEnclosureItem = null
        this.selectedFloorItem = null
        this.selectedRackItem = null

        if (this.selectedTableItem) {
          if (this.selectedTableItem.status !== 'disposed') {
            this.gridlessItem = null
            ewcHolder.getFloorplan().activeEquipmentEwc.forEach(floorItem => {
              if (floorItem.equipmentData.rackLocation === this.selectedTableItem.rackLocation && (floorItem.location.uheight && floorItem.location.uwidth !== 0)) {
                this.localBus.$emit('onSelectFloorItem', floorItem)
                setTimeout(() => {
                  if (this.selectedTableItem.parentEquipmentEwcId && this.selectedTableItem.parentEquipmentEwcId !== floorItem.equipmentData.id) {
                    floorItem.children.forEach(floorItemChild => {
                      if (floorItemChild.equipmentData.id === this.selectedTableItem.parentEquipmentEwcId) {
                        this.localBus.$emit('onSelectEnclosure', floorItemChild)
                        floorItemChild.children.forEach(enclosureChild => {
                          if (enclosureChild.equipmentData.id === this.selectedTableItem.equipmentEwcId) {
                            setTimeout(() => {
                              this.localBus.$emit('onSelectEnclosureItem', enclosureChild)
                            }, [0])
                          }
                        })
                      }
                    })
                  } else if (this.selectedTableItem.parentEquipmentEwcId && this.selectedTableItem.parentEquipmentEwcId === floorItem.equipmentData.id) {
                    floorItem.children.forEach(floorItemChild => {
                      if (floorItemChild.equipmentData.id === this.selectedTableItem.equipmentEwcId && floorItemChild.equipmentModel.capacity > 0) {
                        this.localBus.$emit('onSelectEnclosure', floorItemChild)
                      } else if (floorItemChild.equipmentData.id === this.selectedTableItem.equipmentEwcId && floorItemChild.equipmentModel.capacity === 0) {
                        this.localBus.$emit('onSelectRackItem', floorItemChild)
                      }
                    })
                  }
                }, [0])
              }
            })
          } else {
            this.gridlessItem = this.selectedTableItem
          }
        }
        setTimeout(() => {
          this.gridView = true
          this.tableFieldAssetTag = ''
          this.tableFieldSerial = ''
          this.tableFieldLabel = ''
          this.tableFieldModel = ''
          this.tableFieldRackLocation = ''
          this.tableFieldUnitLocation = ''
          this.tableFieldBay = ''
          this.tableFieldNotes = ''
          this.tableFieldAssetId = ''
          this.tableFieldProjectNumber = ''
          this.tableFieldProjectName = ''
          this.tableFieldDepartment = ''
          this.tableFieldAcquisitionDate = ''
          this.tableFieldDecommissionDate = ''
          this.tableFieldDisposalDate = ''
          this.tableFieldDescription = ''
          this.updateSvg = this.selectedTableItem ? this.selectedTableItem.id : -1
        }, [0])
      },

      navigateToEditEquipment () {
        this.checkingPendingEdits = true
        this.showAlert = false
        let skipFinallyBlock = false
        const equipmentToEditId = this.selectedTableItem && !this.gridView ? this.selectedTableItem.equipmentEwcId : this.selectedItemForEdit.equipmentData.id
        ewcApi.canEditEquipment(equipmentToEditId)
          .then(response => {
            console.log(response)
            this.$router.push({ path: `/ewc/edit/${equipmentToEditId}/${this.selectedFloor.id}/${this.selectedSite.siteId}/` })
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.navigateToEditEquipment()
            } else {
              if (error.response?.status === 409) {
                this.showEditWarningDialog = true
              } else {
                this.showAlert = true
                this.alertType = 'error'
                this.alertMessage = this.alertMessageGeneral
              }
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.checkingPendingEdits = false
            }
          })
      },
      insertAuditPotentialRemoved () {
        this.insertingAuditEquipment = true
        const equipmentEwcId = this.selectedTableItem && !this.gridView ? this.selectedTableItem.equipmentEwcId : this.selectedItemForEdit.equipmentData.id
        ewcApi.insertAuditPotentialRemoved(equipmentEwcId)
          .then(response => {
            console.log(response)
            this.showSnackBar = true
            this.snackBarMessage = 'Succesfully added equipment to audit list'
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.insertAuditPotentialRemoved()
            } else {
              if (error.response.status === 409) {
                this.alertMessage = this.alertMessageDuplicate
              } else {
                this.alertMessage = this.alertMessageGeneral
              }
              this.showAlert = true
              this.alertType = 'error'
            }
          })
          .finally(() => {
            this.insertingAuditEquipment = false
          })
      },
      getAllAuditVerified (gridId) {
        ewcApi.getAllAuditVerified(gridId)
          .then(response => {
            console.log(response)
            this.auditVerifiedRacks = response.data
            this.localBus.$emit('audit-verified-racks', this.auditVerifiedRacks)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getAllAuditVerified(gridId)
            } else {
              this.alertMessage = this.alertMessageGeneral
              this.showAlert = true
              this.alertType = 'error'
            }
          })
      },
    },
  }
</script>

<style css-scoped>
tr.v-data-table__selected {
  background:  #e91e63 !important;
  opacity: 0.7;
  color: white !important;
}
tr.v-data-table__selected .v-icon {
  color: white !important;
}
tr.v-data-table__empty-wrapper{
  text-align: left;
}
</style>
